import { Typography } from 'antd'

const MainHeading = ({ children }) => {
  return (
    <Typography.Title style={{ marginTop: 0 }} level={1}>
      {children}
    </Typography.Title>
  )
}

export default MainHeading
