import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { sidebarItems, sidebarItemsForScanner, sidebarItemsForUser } from './sidebarItems'
import { useSelector } from 'react-redux'
import { userInfo } from '../../../pages/Login/login.slice'
import { getToken } from '../../../config/cookies'

export default function Sidebar(props) {
  const { items } = sidebarItems()
  const { itemsS } = sidebarItemsForScanner()
  const { itemsU } = sidebarItemsForUser()
  const navigate = useNavigate()
  const location = useLocation()
  const signInData = userInfo(useSelector((state) => state)).data ? userInfo(useSelector((state) => state)) : JSON.parse(localStorage.getItem("rl")) ? JSON.parse(localStorage.getItem("rl")) : null
  const [selectedKey, setSelectedKey] = useState(location.pathname)
  const getItem = () => {
    try {
      if (!signInData || !getToken()) {
        window.location.assign('/login')
      } else {
        if (signInData.data.role === "scanner") {
          return itemsS
        }else if (signInData.data.role === "user") {
          return itemsU
        } else {
          return items
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (location.pathname !== selectedKey) {
      setSelectedKey(location.pathname)
    }
  }, [location, selectedKey])

  return (
    <Layout.Sider {...props} className="sidebar">
      <Menu
        mode="inline"
        onSelect={({ key }) => {
          navigate(key)
        }}
        defaultOpenKeys={['sub1']}
        selectedKeys={selectedKey}
        items={getItem()}
      />
    </Layout.Sider>
  )
}
