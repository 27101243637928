import { getItem } from './sidebarGetItem'
import {
  LayoutOutlined,
  DatabaseOutlined, 
  EnvironmentOutlined,
  IdcardOutlined,
  SearchOutlined,
  UserOutlined,
  CreditCardOutlined,
  TeamOutlined,
} from '@ant-design/icons'

const sidebarItems = () => {
  const items = [
    getItem('Dashboard', '/dashboard', <LayoutOutlined />),
    getItem('Voter', '/voter', <IdcardOutlined />),
    getItem('Scanner', '/scanner', <SearchOutlined />),
    getItem('Master', 'sub1', <DatabaseOutlined />, [
      getItem('User', '/user', <UserOutlined />),
      getItem('Souvenir', '/emoney', <CreditCardOutlined />),
      getItem('Candidate', '/candidate', <TeamOutlined />),
      getItem(
        'Location',
        'g1',
        <EnvironmentOutlined />,
        [
          getItem('Province', '/province'),
          getItem('City', '/city'),
          getItem('Subdistrict', '/subdistrict'),
          getItem('Village', '/village'),
        ],
        'group',
      ),
    ]),
  ]

  return { items }
}

const sidebarItemsForScanner = () => {
  const itemsS = [
    getItem('Dashboard', '/dashboard', <LayoutOutlined />),
    getItem('Scanner', '/scanner', <SearchOutlined />),
  ]

  return { itemsS }
}

const sidebarItemsForUser = () => {
  const itemsU = [
    getItem('Dashboard', '/dashboard', <LayoutOutlined />),
    getItem('Voter', '/voter', <IdcardOutlined/>),
    getItem('Scanner', '/scanner', <SearchOutlined />),
  ]

  return { itemsU }
}

export { sidebarItems, sidebarItemsForScanner, sidebarItemsForUser }
