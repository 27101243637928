import { Layout, Row, Drawer } from 'antd'
import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import '../../../src/assets/style/style.css'

export default function MainLayout({ children }) {
  const { Content } = Layout
  const [openBurger, setBurger] = useState(false);
  return (
    <>
      <Layout className="wrapper-layout">
        <div className="logoBurger">
        <MenuOutlined style={{color:"black", fontSize: 30, position:"absolute", margin:"17px 10px 10px 10px"}} onClick={()=>(
          setBurger(true)
          )}/>
          </div>
        <Navbar />
        <Layout>
          <div className="sidebarOpen">
          <Drawer placement="left"  open={openBurger} onClose={() => {
            setBurger(false)
          }}>
            <Sidebar />
          </Drawer>
          <div className='sidebarGone'>
          <Sidebar />
          </div>
          </div>
            
          <Content className="content">
            <Row className="wrapper-children">{children}</Row>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
