import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Space, Typography } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOut, userInfo } from '../../../../pages/Login/login.slice'

export default function AvatarDropDown() {
  const { userProfile } = useSelector(userInfo)

  const items = [
    {
      key: 'signOut',
      danger: true,
      icon: <LogoutOutlined />,
      label: 'Log Out',
    },
  ]
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(signOut())
  }
  return (
    <Dropdown
      menu={{
        items,
        onClick: ({ key }) => {
          if (key === 'signOut') {
            logout()
          }
        },
      }}
    >
      <Space style={{ cursor: 'pointer' }}>
        <Avatar icon={<UserOutlined />} />
        <Typography.Text>{userProfile?.username}</Typography.Text>
      </Space>
    </Dropdown>
  )
}
